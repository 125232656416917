import { Button, Input, Modal, Row, Space, Upload } from "antd";
import HSButton from "../custom/input/button/Button";
import React, { useContext, useState } from "react";
import { changeModalState } from "../../constants/AppConstants";
import { AppContext } from "../../context/AppContext";
import useAttachment from "../../hooks/useAttachment";

const convertBytesToKbOrMb = (bytes) => {
  if (bytes === 0) {
    return "0 Bytes";
  }
  const units = ["Bytes", "KB", "MB"];
  let unitIndex = 0;
  while (bytes >= 1024) {
    bytes /= 1024;
    unitIndex += 1;
  }
  return `${bytes} ${units[unitIndex]}`;
};

const stylePropsObj = {
  sendsms: {
    size: "s",
    inputSize: "normal",
    className: "sendSMSFileUploadModal",
    modalHeader: <span className="fs-6 fw-light">Upload Files</span>,
    buttonClassName: "sendSMSButton",
    contentStyles: {
      fontSize: "12px",
    },
  },
  campaign: {
    size: "m",
    inputSize: "large",
    className: "fileUploadModal",
    modalHeader: <span className="fs-4 fw-medium">Upload Files</span>,
    buttonClassName: "campaignButton",
    contentStyles: {
      fontSize: "14px",
    },
  },
};

const ReusableFileUploadModal = React.memo((props) => {
  const { visible, setVisible, source, fileList, setFileList, integId, skipFilter } = props;

  const styleProps = stylePropsObj[source];

  const [appProperties] = useContext(AppContext);

  const { getSupportedAttachmentTypeString, uploadAttachment } = useAttachment(appProperties, fileList, setFileList, false, integId);

  changeModalState(false);

  const handleUpload = async (file, url, setUrl, setIsInvalidUrl) => {
    const isUploadSuccess = await uploadAttachment(file, url, setUrl, setIsInvalidUrl, null, integId, skipFilter);
    if (isUploadSuccess) {
      setVisible(false);
    }
  };

  const GetUrlInput = () => {
    const [url, setUrl] = useState();
    const [isInvalidUrl, setIsInvalidUrl] = useState(false);
    return (
      <Row>
        <span className="mb-2" style={styleProps.contentStyles}>
          URL Address
        </span>
        <Input
          defaultValue={""}
          key={"urlInput"}
          size={styleProps.inputSize}
          onPressEnter={() => handleUpload(null, url, setUrl, setIsInvalidUrl)}
          type="url"
          autoFocus
          bordered={false}
          style={{ backgroundColor: "#F7F7F8" }}
          placeholder="Enter URL"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
        />
        {isInvalidUrl && <p className="m-0 small text-danger">Please enter a valid url</p>}
        <Button
          data-cy="fileUploadModalSaveBtn"
          type="primary"
          className={`ms-auto mt-4 uploadModalButton ${styleProps.buttonClassName}`}
          onClick={() => {
            handleUpload(null, url, setUrl, setIsInvalidUrl);
          }}
        >
          Save
        </Button>
      </Row>
    );
  };
  const SelectImagesButton = () => {
    const size = convertBytesToKbOrMb(appProperties?.providerCommonObj?.maximumAttachmentSizeInBytes);

    return (
      <Space direction="vertical">
        <Row>
          <Upload
            multiple
            showUploadList={false}
            maxCount={appProperties?.providerCommonObj?.maximumAttachmentLimit}
            accept={getSupportedAttachmentTypeString(appProperties)}
            customRequest={(data) => uploadAttachment(data.file, null, null, null, setVisible, integId, skipFilter)}
          >
            <HSButton
              className={`${styleProps.buttonClassName}`}
              type="primary"
              size={styleProps.size}
              prefixIcon={<div className="actionIconsSprite modalUploadIcon" />}
            >
              Select Files
            </HSButton>
          </Upload>
        </Row>
        <Row>
          <p className="uploadSizeLimitWarning m-0">{`Maximum upload size is ${size}`}</p>
        </Row>
      </Space>
    );
  };

  return (
    <Modal
      onCancel={() => {
        setVisible(false);
      }}
      centered
      className={styleProps.className}
      closeIcon={<div className="actionIconsSprite modalCloseIcon" />}
      open={visible}
      mask={false}
      maskClosable={false}
      footer={null}
    >
      <Space size={styleProps.size} className="p-2 w-100 h-100 modalContent" direction="vertical">
        {styleProps.modalHeader}
        <SelectImagesButton />
        <Row>
          <span style={styleProps.contentStyles}>Or add a file with the file's URL address</span>
        </Row>
        <GetUrlInput />
      </Space>
    </Modal>
  );
});

export default ReusableFileUploadModal;
