import { useEffect, useRef } from "react";

const useInstantFocus = (instantfocus) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (instantfocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [instantfocus]);

  return inputRef;
};

export default useInstantFocus;
