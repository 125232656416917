import { Col, List, Row, Input, Select } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import ReusableFilePreview from "../../../components/custom/ReusableFilePreview";
import MediaUploadIconWrapper from "../../../components/custom/MediaUploadIconWrapper";
import { useContext } from "react";
import { AppContext } from "../../../context/AppContext";

export default function AddSMSTemplateDrawer({
  setInvalidName,
  searchFields,
  filteredFields,
  handleSearchField,
  setFriendlyName,
  disabledSelect,
  invalidField,
  friendlyName,
  invalidName,
  selectedModule,
  handleChangeModule,
  fieldLoading,
  handleSelectFields,
  setInvalidContent,
  handleContentChange,
  content,
  handleTextAreaClick,
  fileList,
  setFileList,
  invalidContent,
  module,
  friendlyNameRef,
  visibility,
  handleChange,
}) {
  const [appProperties] = useContext(AppContext);

  return (
    <div className="templateModal">
      <Row>
        <Col xs={22} md={12} offset={1} id="friendlyName">
          Friendly name<sup>*</sup>
          <div className="mt-2">
            <Input
              onFocus={() => {
                setInvalidName(false);
              }}
              ref={friendlyNameRef}
              type="text"
              name="name"
              id="friendlyNameinput"
              className="hs-bg-off-white focus-border "
              bordered={false}
              onChange={(val) => {
                setFriendlyName(val.target.value);
              }}
              value={friendlyName}
            />
          </div>
          <div className="d-flex">
            <span id="fdnameError" style={{ display: invalidName ? "inherit" : "none" }}>
              Enter friendlyName
            </span>
            <span
              id="friendlyNameId"
              style={{
                color: invalidName ? "#ee3248" : "#C2CFE0",
                marginLeft: invalidName ? "1rem" : "7rem",
              }}
            >
              Eg: US Support,Sale
            </span>
          </div>
        </Col>
        <Col xs={22} md={9} offset={1} id="visibility">
          Visibility
          <div className="mt-2">
            <Select
              suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
              className="selectModule focus-border rounded hs-bg-off-white"
              // defaultValue="Private"
              value={visibility}
              onChange={handleChange}
              bordered={false}
              style={{ width: "9rem", fontSize: "16px", height: "2.5rem" }}
              options={[
                {
                  value: "2",
                  label: "Team",
                },
                {
                  value: "1",
                  label: "Private",
                },
              ]}
            />
            <div className="d-flex" style={{ height: 16 }}>
              <span id="contentError" style={{ display: invalidField ? "inherit" : "none" }}>
                Visibility content is required.
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <div className="ps-3">Module</div>
      <Row>
        <Col xs={24} md={9} offset={1} className="d-flex  align-items-center">
          <div className="mt-2">
            <Select
              suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
              className="selectModule rounded-5 focus-border rounded hs-bg-off-white"
              bordered={false}
              value={selectedModule ? [selectedModule] : module?.[0]}
              size="large"
              style={{ width: "11rem", height: "2.5rem" }}
              onChange={handleChangeModule}
              disabled={disabledSelect}
            >
              {Array.isArray(module) &&
                module.map((module, index) => {
                  return (
                    <Select.Option key={index} value={module}>
                      {module}
                    </Select.Option>
                  );
                })}
            </Select>
            <div className="d-flex" style={{ height: 16 }}>
              <span id="contentError" style={{ display: invalidField ? "inherit" : "none" }}>
                Template content is required.
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col
          xs={22}
          className="d-flex flex-column align-items-center"
          style={{
            borderRadius: 37,
            border: "1px solid #EFEFEF",
            padding: "2px 10px 10px 10px",
            height: "12.5rem",
          }}
        >
          <Input
            prefix={<SearchOutlined style={{ color: "#C2CFE0" }} />}
            placeholder="Search fields"
            className="hs-bg-off-white focus-border mt-2"
            bordered={false}
            style={{
              borderRadius: 37,
              backgroundColor: "#F5F8FA",
              width: "95%",
              height: "2.5rem",
              border: "none",
            }}
            value={searchFields}
            onChange={handleSearchField}
          />
          <div className="justify-content-center text-align-center w-100">
            {fieldLoading ? (
              <div style={{ height: "2.5rem" }}>
                <LoadingOutlined
                  style={{
                    fontSize: 24,
                    display: "flex",
                    marginTop: "4rem",
                    justifyContent: "center",
                    color: "#DEDEDE",
                  }}
                  spin
                />
              </div>
            ) : (
              <div className="list-container-temp">
                {filteredFields.map((field, index) => (
                  <List
                    key={index}
                    dataSource={field?.fields}
                    className="templateFieldBorder"
                    grid={{ gutter: 16, column: 2 }}
                    header={
                      <div align="middle" className="d-flex justify-content-center">
                        <span className="templateFieldsHeader ps-2 pe-2" style={{ textAlign: "center", fontSize: "12px" }}>
                          {field.moduleName}
                        </span>
                      </div>
                    }
                    renderItem={(fieldObj, idx) => (
                      <div className={`d-flex flex-column ${idx % 2 === 0 ? "evenListFields" : "oddListFields"}`}>
                        <List.Item
                          key={idx}
                          className="d-flex justify-content-center templateListItem"
                          onClick={() => handleSelectFields(fieldObj.fieldApiName, field.fieldNameDisplay)}
                        >
                          <span className="truncate-text" style={{ maxWidth: "20ch", cursor: "pointer" }}>
                            {fieldObj.fieldName}
                          </span>
                        </List.Item>
                      </div>
                    )}
                  />
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="d-flex justify-content-start align-items-left mt-4 mb-2 ms-3">
          <span className="font-size-14">Message</span>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={22}>
          <Input.TextArea
            className="templatesContent hs-bg-off-white"
            bordered={null}
            autoFocus
            value={content[selectedModule]}
            type="text"
            onChange={handleContentChange}
            placeholder="Your text goes here"
            onClick={handleTextAreaClick}
            style={{
              borderRadius: 4,
            }}
            onFocus={() => {
              setInvalidContent(false);
            }}
          />
          {fileList && fileList.length > 0 ? (
            <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
              <ReusableFilePreview
                fileList={fileList}
                setFileList={setFileList}
                shape={"circle"}
                direction={"horizontal"}
                customClasses={"ps-3"}
              />
            </div>
          ) : null}

          <div className="ms-2 mb-2">
            <MediaUploadIconWrapper fileList={fileList} setFileList={setFileList} integId={appProperties?.randomIntegId} skipFilter="true" />
          </div>
        </Col>
      </Row>
      <div className="d-flex">
        <span id="contentError" style={{ display: invalidContent ? "inherit" : "none" }}>
          Template content is required.
        </span>
      </div>
    </div>
  );
}
