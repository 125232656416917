export const config = {
  button: {
    size: {
      m: {
        customClasses: "hs-med-button hs-fs-16 px-3",
      },
      l: {
        customClasses: "hs-big-button hs-fs-16 px-3",
      },
      xs: {
        customClasses: "hs-xs-button",
      },
      s: {
        customClasses: "hs-s-button",
      },
      xl: {
        customClasses: "hs-xl-button px-3",
      },
    },
    type: {
      link: {
        customClasses: "hs-button-link",
      },
      primary: {
        customClasses: "hs-button-primary",
        textCustomClasses: "hs-text-primary",
      },
      outline: {
        customClasses: "hs-button-outline",
        textCustomClasses: "hs-text-outline",
      },
      secondary: {
        textCustomClasses: "hs-text-outline",
        customClasses: "",
      },
    },
    variant: {
      cancel: {
        customClasses: "hs-button-v-cancel",
      },
    },
    customClasses: "d-flex align-items-center hs-border-10 w-100 hs-button ",
  },
  modal: {
    type: {
      success: {
        icon: {
          customClasses: "successInfoIcon",
        },
      },
    },
    header: {
      size: {
        s: {
          customClasses: "fs-6 fw-light",
        },
        m: {
          customClasses: "fs-4 fw-medium",
        },
      },
    },
  },
  input: {
    size: {
      m: {
        customClasses: "h-50",
      },
      l: {
        customClasses: "h-60",
      },
    },
    customClasses: "hs-fs-14 rounded hs-input-box hs-placeholder-text",
  },
};
