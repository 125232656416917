import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Col, Divider, Form, Input, Row, Select, Spin, Switch, Tooltip, message, theme } from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { NoChannelPresent, SetAsDefaultPhone, initPusher } from "../utils/CommonVessels";

import "../../assets/css/outbound.css";
import MediaUploadIconWrapper from "../../components/custom/MediaUploadIconWrapper";
import ReusableFilePreview from "../../components/custom/ReusableFilePreview";
import { ASSIST_MAIL } from "../../constants/AppConstants";
import { ProviderCommonObj, ProviderExtendedCommonObj } from "../../constants/ProviderConstants";
import useConfigurePhoneField from "../../hooks/useConfigurePhone";
import useHttp from "../../hooks/useHttp";
import ConversationWrapper from "../ConversationContext";
import { SavedTemplatesSelect } from "../outbound/SavedTemplatesSelect";
import {
  failureNotification,
  failureNotificationWithBtn,
  failureNotificationWithLink,
  successNotification,
  successNotificationWithBtn,
} from "../utils/CommonNotifications";
import { getRandomNumber, mapToMediaList, openServiceNotAuthorizedModal } from "../utils/commonUtils";
import { FundsMonitor } from "../utils/FundsMonitor";
import { AppContext } from "./../../context/AppContext";
import { TopMenuItems } from "./../utils/MoreMenu";
import ComponentPreview from "../../components/custom/ComponentPreview";
const { TextArea } = Input;

function SendSMS(props) {
  const { fetchPhoneFieldConfiguration } = useConfigurePhoneField();
  const [appProperties, setAppProperties] = useContext(AppContext);
  const { fetchData } = useHttp();
  const { token } = theme.useToken();
  const sendSMSIcon = <div className="actionIconsSprite sendSMSIcon h-100 w-100"></div>;
  const [appLoading, setAppLoading] = useState(props.appLoading);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewResponse, setPreviewResponse] = useState();

  const info = () => {
    messageApi.open({
      content: (
        <>
          <Badge status="error" /> Alert: Verify your 10DLC status within your{" "}
          <Button
            type="link"
            onClick={() => {
              window.open("/channels/textsms/helloSend?" + urlParams);
              messageApi.destroy();
            }}
            style={{ whiteSpace: "nowrap" }}
          >
            Phone Settings
          </Button>
          <a href={"/channels/textsms/helloSend?" + urlParams} rel="noreferrer" target="_blank"></a>
        </>
      ),
      className: "tendlcAlert",
      duration: 3,
    });
  };

  const [savedPhoneNumbers, setSavedPhoneNumbers] = useState([]);
  const [savedMessagingServices, setSavedMessagingServices] = useState([]);
  const [loadFromServiceList, setLoadFromServiceList] = useState([]);
  const [loadTemplates, setLoadTemplates] = useState(false);
  const [loadingButton, setLoadingButton] = useState(sendSMSIcon);
  const [fromNumber, setFromNumber] = useState("");
  const [defaultFromNumber, setDefaultFromNumber] = useState("");
  const [toNumberInFormView, setToNumberNumberInFormView] = useState("");
  const [noChannelPresentComponent, setNoChannelPresentComponent] = useState(true);
  const [recordsObj, setRecordsObj] = useState({});
  const [templateId, setTemplateId] = useState("");
  const [showMMS, setShowMMS] = useState(true);
  const [isBulkSMS, setIsBulkSMS] = useState(props.bulk);
  const [savedNumbersList, setSavedNumbersList] = useState([]);
  const [alphaSender, setAlphaSender] = useState("false");
  const [messagingServiceId, setMessagingServiceId] = useState("");
  const [helloSend, setHelloSend] = useState(false);
  const [userId, setUserId] = useState("");
  const [resource, setResource] = useState("");
  const [resourceInPlural, setResourceInPlural] = useState(resource);
  const [resourceId, setResourceId] = useState("");
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [balanceCredits, setBalanceCredits] = useState(0);
  const [phoneFieldConfiguration, setPhoneFieldConfiguration] = useState();
  const [sendSMSForm] = Form.useForm();

  const [isChannelsPresent, setIsChannelsPresent] = useState(true);
  const [showChatView, setShowChatView] = useState(isBulkSMS ? false : true);
  const [integId, setIntegId] = useState();
  const urlParams = useMemo(() => new URLSearchParams(window.location.search), []);

  urlParams.delete("sideBar");
  urlParams.delete("topBar");
  // var resource = urlParams?.get("resource") || appProperties.module;
  // var userId = urlParams.get("userId");
  var companyId = urlParams.get("companyId");
  var selectedIds = urlParams.get("selectedIds");
  var filter = urlParams.get("filter");
  var excludedIds = urlParams.get("excludedIds");
  var view = urlParams.get("view");

  useEffect(() => {
    if (appProperties?.serviceInstalled) {
      setIsChannelsPresent(true);
    }
  }, [appProperties?.serviceInstalled]);
  useEffect(() => {
    let licenseInfo = appProperties?.licenseObj?.licenseDetails;
    if (licenseInfo) {
      setBalanceCredits(licenseInfo?.creditsPurchased - licenseInfo?.creditsConsumed);
    }
  }, [appProperties?.licenseObj?.licenseDetails]);

  useEffect(() => {
    let integId = appProperties?.installedApps?.find((obj) => obj?.right?.service_name === "hello_send")?.integProps?.integId;
    if (integId) {
      fetchData("hellosend/account/" + integId + "/campaign/registration?type=PROFILE", "POST", null, appProperties).then(function (response) {
        if (response) {
          response = JSON.parse(response);
          let steps = response?.data?.steps;
          setCampaignStatus(steps?.[4]?.campaign?.campaignStatus);
        }
      });
    }
  }, [fetchData, appProperties?.installedApps]);

  useEffect(() => {
    if (phoneFieldConfiguration && !showChatView) {
      appProperties?.controller?.fetchContact(fetchData, appProperties, phoneFieldConfiguration).then(function (contactNumbersObj) {
        if (contactNumbersObj) {
          const phoneNumbers = contactNumbersObj.map((record) => record.phone).join(",");
          appProperties.phone = phoneNumbers;
          setToNumberNumberInFormView(phoneNumbers);
          setRecordsObj(contactNumbersObj);

          if (contactNumbersObj.length > 1) {
            setIsBulkSMS(true);
          }
        }
      });
    }
  }, [appProperties?.controller, fetchData, phoneFieldConfiguration]);

  useEffect(() => {
    if (!showChatView) {
      setHelloSend(savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber)?.isHelloSendPhoneNumber === "true" ? true : false);
    }
    let numberObj = savedNumbersList?.find((obj) => obj?.phoneNumber === fromNumber);
    let integ = numberObj?.integId;
    setIntegId(integ);
    let rightServiceName = appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === numberObj?.integId)?.right?.service_name;
    let assignerObj1 = Object.assign(ProviderCommonObj, ProviderExtendedCommonObj[rightServiceName]);
    appProperties.providerCommonObj = assignerObj1;
    appProperties.rightServiceName = rightServiceName;

    if (appProperties?.providerCommonObj && !appProperties.providerCommonObj["isMMSSupported"]) {
      setShowMMS(false);
    } else {
      setShowMMS(true);
    }
  }, [fromNumber, appProperties?.providerCommonObj, savedNumbersList]);

  function handleAlphaSender(phoneNumberList, selectedNumber) {
    console.log("selected phone handleAlphaSender >>>>>>>", phoneNumberList);
    console.log("selected phone fromNumber >>>>>>>", selectedNumber);

    const filteredOption = phoneNumberList.filter((phoneObj) => phoneObj.value === selectedNumber);

    console.log("filteredOption >>>>>>>>>>>>>>>>> ", filteredOption);
    if (filteredOption.length > 0) {
      let isAlphaSender = filteredOption[0].alphaSender;
      let messageServiceId = filteredOption[0].messageServiceId;
      console.log("filteredOption >>>>>> isAlphaSender >>>>>", isAlphaSender);

      if (isAlphaSender !== undefined && isAlphaSender === "true") {
        setAlphaSender("true");
        setMessagingServiceId(messageServiceId);
      }
    }
  }

  function handleFromNumberChange(selectedPhoneNumber) {
    if (selectedPhoneNumber === "add::::phone") {
      window.open("/channels/textsms?" + urlParams, "_blank");
    } else {
      setFromNumber(selectedPhoneNumber);
      handleAlphaSender(savedPhoneNumbers, selectedPhoneNumber);
    }
  }
  function handleDefaultNumberChange(selectedPhoneNumber) {
    if (selectedPhoneNumber === "add::::phone") {
      // window.open("/channels/textsms?" + urlParams, "_blank");
    } else {
      setDefaultFromNumber(selectedPhoneNumber);
    }
  }
  function handleToNumberChange(toNumberList) {
    setToNumberNumberInFormView(toNumberList);
  }

  useEffect(() => {
    var phoneObj = appProperties?.savedNumbers?.filter((obj) => obj.isMessagingService !== "true");
    var msgObj = appProperties?.savedNumbers?.filter((obj) => obj.isMessagingService === "true");
    if (msgObj?.length > 0 && phoneObj?.length > 0) {
      phoneObj = [
        ...(phoneObj || []),
        {
          label: (
            <Divider plain style={{ margin: 0, fontSize: "12px" }} orientation="left">
              Message Service ID
            </Divider>
          ),
          value: "divider",
          disabled: true,
        },
        ...msgObj,
      ];
    } else if (msgObj?.length > 0) {
      phoneObj = [...msgObj];
    }
    setSavedNumbersList(phoneObj);
    if (phoneObj !== undefined) {
      phoneObj = phoneObj?.map((item) => {
        return {
          ...item,
          value: item.phoneNumber,
          label: item?.phoneNumber ? (
            <Tooltip title={item?.phoneNumber}>
              <div className="addPhone-sendsms">
                <div className="d-flex align-items-center addPhone-sendsms">
                  <div
                    className={
                      "numberlist-sprites numberlist-" +
                      appProperties?.installedApps?.find((obj) => obj?.integProps?.integId === item.integId)?.right?.service_name
                    }
                  ></div>
                  <span>
                    {(() => {
                      let labelName = (item.friendlyName || "") + `(${item.phoneNumber})`;
                      if (labelName?.length > 30) {
                        return labelName.slice(0, 30) + "...";
                      } else {
                        return labelName;
                      }
                    })()}
                  </span>
                  <SetAsDefaultPhone setSavedNumbers={setLoadFromServiceList} phoneObj={phoneObj} item={item} />
                </div>
              </div>
            </Tooltip>
          ) : (
            item?.label
          ),
        };
      });
    } else {
      phoneObj = [];
    }
    setLoadTemplates(true);
    if (appProperties?.savedNumbers?.length > 0) {
      const defaultPhoneNumber = phoneObj.find((obj) => obj?.defaultNumber === 1)?.phoneNumber || phoneObj[0]?.value;
      setDefaultFromNumber(defaultPhoneNumber);
      setFromNumber(defaultPhoneNumber);
    } else if (appProperties?.serviceInstalled && !appProperties?.authorizeObj) {
      appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then(function (params) {
        var redirectLink = "channels/textsms?" + params;
        failureNotificationWithBtn("Error", "Phone number not added. Add a phone number to proceed.", "Add phone", redirectLink);
      });
    }
    setSavedPhoneNumbers(phoneObj);
    setLoadFromServiceList(phoneObj);
    setSavedMessagingServices(msgObj);
    setLoadTemplates(true);
    if (appProperties?.phone) {
      setToNumberNumberInFormView(appProperties.phone);
    }
    if (appProperties?.controller) {
      appProperties.controller.getAssociatedObjectId().then(function (data) {
        setResourceId(data);
      });

      appProperties.controller.getAssociatedObjectType().then(async function (data) {
        setResource(data);
      });
      appProperties.controller.getAssociatedObjectTypeInPlural().then(async function (data) {
        setResourceInPlural(data);
      });
    }
  }, [appProperties.savedNumbers, appProperties, fetchData, urlParams]);
  useEffect(() => {
    if (resource && (!appProperties?.module || appProperties.module !== resource)) {
      setAppProperties((prev) => ({
        ...prev,
        module: resource,
      }));
    }
  }, [resource]);

  useEffect(() => {
    if (appProperties?.module && appProperties?.companyId) {
      const fetchConfig = async () => {
        const phoneFieldConfig = await fetchPhoneFieldConfiguration(appProperties, "GET");
        if (phoneFieldConfig) {
          setPhoneFieldConfiguration(phoneFieldConfig);
        }
      };

      fetchConfig();
    }
  }, [appProperties?.module, appProperties?.companyId]);

  const sendMessage = (fileList, setFileList, message, setMessage) => {
    const antIcon = <LoadingOutlined className="loading-button" spin />;
    setLoadingButton(() => <Spin indicator={antIcon} />);

    if (!isBulkSMS && !toNumberInFormView) {
      failureNotification(null, "The 'To' number is not a valid phone number.");
      setLoadingButton(sendSMSIcon);
    } else if (!message && !previewResponse?.components) {
      failureNotification(null, "Message body required");
      setLoadingButton(sendSMSIcon);
    } else if (helloSend && props?.balanceCredits < 0.5) {
      failureNotification("Message not sent. Insufficient balance in your wallet. Top up now to reactivate the service.");
      setLoadingButton(sendSMSIcon);
    } else if (message !== "" || previewResponse?.components) {
      const phoneNumberEntry = savedNumbersList?.find((entry) => entry.phoneNumber === fromNumber);
      let integId = phoneNumberEntry?.integId;
      const mediaFileList = fileList
        ? fileList.map((file) => {
            return {
              name: file.name ?? "file",
              url: file.url,
              size: file.size ?? -1,
              contentType: file.contentType ?? "dummy/dummy",
              thumbnailUrl: file.thumbnailUrl,
            };
          })
        : [];
      var payload = {
        from: fromNumber,
        to: toNumberInFormView,
        messageBody: message,
        associatedObjectId: resourceId,
        associatedObjectType: resource,
        mediaFileList: mediaFileList,
        templateId: templateId,
        alphaSender: alphaSender,
        messageServiceId: messagingServiceId,
        userId: urlParams.get("userId"),
        components: previewResponse?.components,
        fieldNameAndValueMap: previewResponse?.fieldNameAndValueMap,
      };
      let formData = null;

      let header = null;
      var targetUrl = "";

      if (recordsObj && recordsObj.length > 1) {
        payload = {
          ...payload,
          recordsObj: recordsObj,
          batchSMS: "true",
        };
      }
      if (isBulkSMS) {
        const filterJson = JSON.parse(filter);
        payload = {
          ...payload,
          view: view,
          selectedIds: selectedIds,
          filterId: filterJson?.filter_id ?? "",
          excludedIds: excludedIds,
          userId: userId,
          moduleName: resource,
          campaignName: `${resourceInPlural?.toUpperCase()}_Campaign_${getRandomNumber(3)}`,
          filterName: `Selected ${resourceInPlural}`,
          messageScheduled: false,
          // campaignName: sendSMSForm?.getFieldValue("campaignName"),
        };

        header = { "Content-Type": "multipart/form-data" };

        formData = new FormData();
        formData.append("data", JSON.stringify(payload));
        payload = formData;

        targetUrl = `${integId}/campaign/send`;
      } else {
        targetUrl = `omessage/${integId}/send`;
      }
      let rightPhoneObj = appProperties.installedApps.find((obj) => obj?.integProps?.integId === integId);
      if (rightPhoneObj?.right?.auth?.authorized) {
        fetchData(targetUrl, "POST", payload, appProperties, header)
          .then(function (response) {
            response = JSON.parse(response);
            if (response.data.status === true) {
              if (helloSend && props.balanceCredits < 11) {
                failureNotification("Message queued successfully. Low balance in your wallet, top up for uninterrupted service.");
              }
              if (isBulkSMS) {
                let notificationMessage = "Message campaign started successfully! Monitor analytics for insights.";
                successNotificationWithBtn(notificationMessage, appProperties);
              } else {
                successNotification("Message queued successfully");
              }
              setLoadingButton(sendSMSIcon);
              setMessage("");
            } else {
              if (isBulkSMS) {
                failureNotification("Campaign failed to initialize", response.data.error);
              } else if (response?.data?.data) {
                failureNotification("", response.data.data);
              } else if (response?.data?.license) {
                failureNotificationWithLink(response.data?.license?.description, "contact support .", `mailto:${ASSIST_MAIL}`);
              }
              setLoadingButton(sendSMSIcon);
            }
          })
          .catch(function () {
            setMessage("");
            setLoadingButton(sendSMSIcon);
            failureNotification("", "Message Failed ! ");
          })
          .finally(() => {
            setFileList(() => []);
            setPreviewResponse();
            setTemplateId();
          });
      } else {
        if (rightPhoneObj?.right) {
          openServiceNotAuthorizedModal(rightPhoneObj?.right, appProperties);
        }
        setLoadingButton(sendSMSIcon);
      }
    }
  };

  useEffect(() => {
    setToNumberNumberInFormView(appProperties.phone);
  }, [appProperties.phone, appProperties]);
  return (
    <>
      {contextHolder}

      <div className="w-100 h-100 bg-white">
        <div className="w-100 h-100 row d-flex justify-content-center p-0 m-0">
          <div className="w-100 h-100 col d-flex justify-content-center p-0 m-0">
            <div className="parent-element overflow-hidden">
              <div className="" style={{ height: "33px" }}>
                <Row>
                  <Col span={12} className="d-flex ps-2 justify-content-start align-items-center">
                    {appProperties?.serviceInstalled && helloSend && (
                      <div className="ms-2 mb-1">
                        <FundsMonitor popOver={false} />
                      </div>
                    )}
                  </Col>
                  <Col span={6} className="d-flex align-items-center justify-content-end pe-1">
                    {appProperties?.serviceInstalled && helloSend && campaignStatus !== "VERIFIED" && (
                      <Badge dot={true} className="d-flex align-items-center me-3" style={{ top: 0, right: 0 }}>
                        <div className="actionIconsSprite tenDlc-icon" onClick={info} style={{ cursor: "pointer" }}></div>
                      </Badge>
                    )}
                    {appProperties?.serviceInstalled && !isBulkSMS && (
                      <>
                        <span className="pe-1 " style={{ whiteSpace: "nowrap", fontSize: 12 }}>
                          Chat view{" "}
                        </span>
                        <Switch
                          className="addPhoneSwitch-chatView"
                          value={showChatView}
                          size="small"
                          checked={showChatView}
                          onChange={(e) => {
                            localStorage.setItem("chatview", e);
                            setShowChatView(e);
                          }}
                        />
                      </>
                    )}
                  </Col>
                  <Col span={3} className="pt-2 pe-1 d-flex justify-content-end align-item-between">
                    <Button
                      data-sleek-changelog
                      data-badge-changelog
                      className="border-0 d-flex align-items-center justify-content-between pb-2"
                      style={{
                        border: "none",
                        boxShadow: "none",
                      }}
                    >
                      <div className="actionIconsSprite announcement mb-2"></div>
                    </Button>
                  </Col>
                  <Col span={3} className="d-flex justify-content-end align-items-center pe-4">
                    <TopMenuItems appProperties={appProperties} isBulkSMS={isBulkSMS} />
                  </Col>
                </Row>
              </div>
              {isChannelsPresent ? (
                <>
                  {!showChatView ? (
                    <div className="container d-flex justify-content-center w-100 h-100 bg-white">
                      <div className="row w-100 h-100">
                        <div className="col h-100">
                          <Card bordered={null} style={{ boxShadow: "none", background: "#fff" }} bodyStyle={{ padding: "0px" }}>
                            <Form form={sendSMSForm} layout="vertical">
                              <PhoneNumbersList
                                handleFromNumberChange={handleFromNumberChange}
                                handleDefaultNumberChange={handleDefaultNumberChange}
                                savedNumbers={loadFromServiceList}
                                savedMessagingServices={savedMessagingServices}
                                defaultFromNumber={defaultFromNumber}
                              />
                              {isBulkSMS ? (
                                <>
                                  <RecipientsForBulk resource={resourceInPlural} />
                                  {/* <FormInput
                                  formItemName={"campaignName"}
                                  formItemLabel={"Campaign Name"}
                                  formItemRules={[
                                    {
                                      required: true,
                                      message: (
                                        <span className="position-absolute">
                                          Required!
                                        </span>
                                      ),
                                    },
                                  ]}
                                  formExtraContent="Only for analytics purposes, not exposed to your customers."
                                  instantfocus={true}
                                /> */}
                                </>
                              ) : (
                                <RecipientsNumber
                                  handleToNumberChange={handleToNumberChange}
                                  toNumber={toNumberInFormView}
                                  setToNumber={setToNumberNumberInFormView}
                                  setPhoneFieldConfiguration={setPhoneFieldConfiguration}
                                  phoneFieldConfiguration={phoneFieldConfiguration}
                                />
                              )}
                              <MessagingArea
                                sendMessage={sendMessage}
                                loadingButton={loadingButton}
                                templateId={templateId}
                                setTemplateId={setTemplateId}
                                loadTemplates={loadTemplates}
                                showMMS={showMMS}
                                setLoading={props.setLoading}
                                resource={resource}
                                integId={integId}
                                appProperties={appProperties}
                                fromNumber={fromNumber}
                                toNumber={toNumberInFormView}
                                setToNumber={setToNumberNumberInFormView}
                                previewResponse={previewResponse}
                                setPreviewResponse={setPreviewResponse}
                              />
                            </Form>
                          </Card>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ConversationWrapper
                      chatView={true}
                      setHelloSend={setHelloSend}
                      fromSendSMSPage={true}
                      phoneFieldConfiguration={phoneFieldConfiguration}
                      setPhoneFieldConfiguration={setPhoneFieldConfiguration}
                      setToNumberNumberInFormView={setToNumberNumberInFormView}
                    />
                  )}
                </>
              ) : (
                appLoading && noChannelPresentComponent && <NoChannelPresent newTab={true} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function SendSMSButton(props) {
  const { sendMessage, message, setMessage, loadingButton, fileList, setFileList } = props;
  return (
    <Col span={6} className="d-flex justify-content-end align-items-center">
      <div
        onClick={() => sendMessage(fileList, setFileList, message, setMessage)}
        className="me-3 d-flex align-items-center justify-content-center sendSMSIconWrapper"
      >
        {loadingButton}
      </div>
    </Col>
  );
}

function SendMessageButtonWithIconsArea(props) {
  const { sendMessage, message, setMessage, loadingButton, sendSMSLoading, fileList, integId, setFileList } = props;
  return (
    <Row className="rounded-bottom hs-bg-off-white pb-3">
      <div className="h-auto w-100 d-flex justify-content-between align-items-end">
        <MediaUploadIconWrapper fileList={fileList} setFileList={setFileList} integId={integId} showMMS />
        <SendSMSButton
          fileList={fileList}
          setFileList={setFileList}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          loadingButton={loadingButton}
          sendSMSLoading={sendSMSLoading}
        />
      </div>
    </Row>
  );
}

const PhoneNumbersList = React.memo((props) => {
  const handleFromNumberChange = props.handleFromNumberChange;
  const handleDefaultNumberChange = props.handleDefaultNumberChange;
  const defaultFromNumber = props.defaultFromNumber;

  let savedNumbers = props.savedNumbers;
  if (!savedNumbers.some((item) => item.value === "add::::phone")) {
    savedNumbers.push({
      value: "add::::phone",
      label: (
        <Button icon={<PlusOutlined style={{ verticalAlign: "middle" }} />} type="" className="addPhone-sendsms">
          Add Phone
        </Button>
      ),
    });
  }
  return (
    <Form.Item label={<span className="hs-fs-13">From</span>} className="mb-0">
      <Row>
        <Select
          tabIndex={3}
          className="rounded hs-bg-off-white hs-focus-border h-45"
          id="fromPhoneNumber"
          value={defaultFromNumber !== undefined ? defaultFromNumber : ""}
          options={savedNumbers}
          autoFocus
          bordered={null}
          suffixIcon={<div className="actionIconsSprite fromNumberDropDownIcon" />}
          onChange={(v) => {
            console.log("selected from number >>>>>>>>>>>", v);
            handleFromNumberChange(v);
            handleDefaultNumberChange(v);
          }}
        />
      </Row>
    </Form.Item>
  );
});

function RecipientsForBulk(props) {
  return (
    <div className="my-3">
      <span className="hs-fs-13">
        To: <b>Selected {props.resource}</b>
      </span>
    </div>
  );
}

function RecipientsNumber(props) {
  const { handleToNumberChange, toNumber, setToNumber, setPhoneFieldConfiguration, phoneFieldConfiguration } = props;
  return (
    <Form.Item label={<span className="hs-fs-13">To</span>} className="mt-1">
      <Row>
        <Input
          tabIndex={4}
          className="rounded hs-bg-off-white hs-focus-border h-45 hs-fs-14"
          value={toNumber}
          bordered={null}
          id="toPhoneNumber"
          onChange={(e) => handleToNumberChange(e.target.value)}
        />
        <div></div>
        <div className="d-flex w-100 justify-content-between">
          <span className="hs-fs-11 mt-1 mb-2">{"Include country code in 'TO' number"}</span>
        </div>
      </Row>
    </Form.Item>
  );
}

function MessagingArea(props) {
  const {
    sendMessage,
    loadingButton,
    loadTemplates,
    showMMS,
    resource,
    integId,
    appProperties,
    fromNumber,
    templateId,
    toNumber,
    setTemplateId,
    previewResponse,
    setPreviewResponse,
  } = props;
  const { fetchData } = useHttp();
  const [message, setMessage] = useState();
  const [messageAreaLoading, setMessageAreaLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [pusherChannel, setPusherChannel] = useState(null);
  const messageBodyRef = useRef();

  const handlePusherCall = (e) => {
    if (!pusherChannel) {
      initPusher(fetchData, appProperties).then((res) => {
        setPusherChannel(res);
      });
    }
  };

  useEffect(() => {
    if (pusherChannel) {
      pusherChannel?.channel?.bind("CONVERSATION_UPDATE", (data) => {
        // throw error if comes
        if (data) {
          try {
            let response = JSON.parse(data?.data);
            console.log("CONVERSATION_UPDATE >>>>>", response);
            if (
              [fromNumber, toNumber, toNumber?.replace(/[\s()-]/g, "")].includes(response.sender_id) &&
              [fromNumber, toNumber, toNumber?.replace(/[\s()-]/g, "")].includes(response.receiver_id) &&
              response?.direction === 2
            ) {
              if (["failed", "undelivered", "delivered"].includes(response.messageStatus)) {
                response.class = "input_error";
                if (response.errorCode || response?.errorMessage) {
                  failureNotification(response?.errorCode, response?.errorMessage);
                }
              }
            }
          } catch (e) {
            console.error("ERROR::::", e);
          }
        }
      });
      return () => {
        pusherChannel?.channel?.unbind("CONVERSATION_UPDATE");
        pusherChannel.dispose();
      };
    }
  }, [pusherChannel]);
  function handleMessage(messageText) {
    setMessage(messageText);
    handlePusherCall(messageText);
  }

  function handleMedia(mediaFile) {
    setFileList(mediaFile ? mapToMediaList(mediaFile, false) : []);
  }

  useEffect(() => {
    messageBodyRef?.current?.focus();
  }, [templateId]);

  const handlePreviewRemove = () => {
    setPreviewResponse((prev) => {
      return { ...prev, components: null };
    });
    setTemplateId(null);
  };

  return (
    <Form.Item className="mb-0">
      <MessagingHeaderRow
        setLoading={props.setLoading}
        setTemplateId={props.setTemplateId}
        handleMessage={handleMessage}
        loadTemplates={loadTemplates}
        resource={resource}
        setMessageAreaLoading={setMessageAreaLoading}
        appProperties={appProperties}
        setPreviewResponse={setPreviewResponse}
        handleMedia={handleMedia}
        integId={integId}
      />
      <div className="rounded hs-bg-off-white hs-focus-border">
        {!previewResponse?.components ? (
          <>
            <Spin spinning={messageAreaLoading}>
              <TextArea
                ref={messageBodyRef}
                tabIndex={1}
                autoFocus
                className="hs-fs-14"
                placeholder={"Your text goes here"}
                size="large"
                autoSize={{ maxRows: 8, minRows: 3 }}
                bordered={false}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                  handlePusherCall(e);
                }}
              />
            </Spin>
            <div className="d-flex align-items-end" style={{ minHeight: "48px" }}>
              <ReusableFilePreview
                fileList={fileList}
                setFileList={setFileList}
                shape={"circle"}
                direction={"horizontal"}
                customClasses={"ps-3"}
              />
            </div>
          </>
        ) : (
          <ComponentPreview
            componentsData={previewResponse?.components}
            chatView={true}
            source="sendsmsform"
            handleRemove={handlePreviewRemove}
          />
        )}
        <SendMessageButtonWithIconsArea
          fileList={fileList}
          setFileList={setFileList}
          showMMS={showMMS}
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          integId={integId}
          loadingButton={loadingButton}
        />
      </div>
    </Form.Item>
  );
}

function MessagingHeaderRow(props) {
  const {
    setMessageAreaLoading,
    setTemplateId,
    setLoading,
    handleMessage,
    resource,
    loadTemplates,
    appProperties,
    handleMedia,
    integId,
    setPreviewResponse,
  } = props;
  const openAddTemplateDrawer = true;
  return (
    <>
      <Row className="pb-1 d-flex justify-content-between">
        <MessagingHeader />
        <SavedTemplatesSelect
          tabIndex={2}
          moduleName={resource || appProperties.moduleName}
          setMessageAreaLoading={setMessageAreaLoading}
          setTemplateId={setTemplateId}
          setLoading={setLoading}
          handleMessage={handleMessage}
          loadTemplates={loadTemplates}
          openAddTemplateDrawer={openAddTemplateDrawer}
          showPreview={true}
          handleMedia={handleMedia}
          integrationId={integId}
          refetchOnModuleChange={true}
          setPreviewResponse={setPreviewResponse}
        />
      </Row>
    </>
  );
}

function MessagingHeader() {
  return (
    <Col span={6} className="d-flex justify-content-start align-items-center">
      <span className="hs-fs-14">Message</span>
    </Col>
  );
}

export default SendSMS;
