import { urlParams } from "./../constants/AppConstants";

import AppExtensionsSDK from "@pipedrive/app-extensions-sdk";
import SendSmsDemo from "../assets/video/SendSmsVideo.mp4";
import Thumbnail from "../assets/images/sendsms-thumbnail.png";

function getAllUrlParams() {
  var allUrlParams = new URLSearchParams(window.location.search);
  var resourceObj = {};
  for (var key of allUrlParams.keys()) {
    resourceObj[key] = allUrlParams.get(key);
  }
  var resourceStr = JSON.stringify(resourceObj);
  return encodeURIComponent(resourceStr);
}
export let PipeDriveSMS = {
  primaryPhoneApiName: "phone",
  secondaryPhoneApiName: "mobile",
  service: "Pipedrive",
  isOmniLicense: true,
  productId: "102",
  isInboxEnabled: true,
  isWorkflowTableFormat: true,
  isWorkflowtext: "Webhook",
  workFlowIconInTemplates: false,
  api: "api/v2/",
  addUserButton: false,
  redirectPath: "/persons/list",
  associatedLookup: false,
  automationPageHelpVideo: [
    {
      headline: "Automate SMS / WhatsApp messages for Deal Stage Changes",
      sourceUrl: "https://www.youtube.com/embed/nxXgaxZPXU4?rel=0",
      steps: ["Send automatic messages to clients when deals progress in Pipedrive"],
    },
    {
      headline: "Automate SMS / WhatsApp messages  for Pipedrive Activities",
      sourceUrl: "https://www.youtube.com/embed/LuegcRXHeNM",
      steps: ["Setup automation whenever an activity is newly created in Pipedrive"],
    },
    {
      headline: "Automate SMS / WhatsApp messages for Appointment Scheduling",
      sourceUrl: "https://www.youtube.com/embed/ojia6Q7VXYM",
      steps: ["Manage your appointments with automated messages for scheduling, rescheduling, and cancellations."],
    },
    {
      headline: "Configuring Messaging Automation",
      sourceUrl: "https://www.youtube.com/embed/iv2i324FmRc?rel=0",
      steps: ["Learn how to set up automatic SMS/WhatsApp notification in 2 simple steps"],
    },
    {
      headline: "Automate SMS / WhatsApp messages in Pipedrive.",
      sourceUrl: "https://www.youtube.com/embed/iUayvyukksM?rel=0",
      steps: [`Learn to automate in Pipedrive for your business needs.`],
    },
  ],
  sendSmsVideoComponent: <video width={"100%"} height={"100%"} className="mt-2" poster={Thumbnail} src={SendSmsDemo} controls />,
  sideBarExtend: {
    homeSideBar: false,
    serviceSideBar: true,
    inboxSidebar: true,
    conversationSidebar: false,
    sendSmsSidebar: false,
    sendBulkSmsSidebar: false,
    campaignSidebar: true,
    campaignOverviewSidebar: true,
    phoneSidebar: false,
    pagesSidebar: false,
    templateSideBar: true,
    workFlowSideBar: true,
    usersSideBar: true,
    automationSideBar: false,
    workflowTabName: "Webhook",
    workflowTabSingularName: "Webhook",
    hideSMSTabsInSideBar: false,
    settingSideBar: true,
  },
  filterTypeMap: {
    user_id: 1,
    filter_id: 2,
    team_id: 3,
    everyone: null,
  },
  planDetails: {
    topPlan: "Gold",
    topPlanKey: "PD_GOLD",
    topPlanId: "Gold-USD-Monthly",
    totalUsers: 3,
  },
  loadScripts: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },

  init: function () {
    return new Promise((resolve, reject) => {
      console.log(" :::::::::::; PIPEDRIVE INIT CALLLED :::::::::: ");
      const id = urlParams.get("id");
      console.log("under init function in pipedrive :::::: id >>>>>>>>>>>>>>>>>", id);

      let a = Date.now();
      try {
        console.log("APP SDK START TIME >>>>>>>>>>", a);
        const sdk = new AppExtensionsSDK({ identifier: id })
          .initialize({
            size: { height: 550, width: 550 },
          })
          .then(() => {
            let b = Date.now();
            let c = b - a;
            console.log("APP SDK END SUCCESSFUL TIME and RESOLVED >>>>>>>>>>", c);
          })
          .catch(() => {
            let b = Date.now();
            let c = b - a;
            console.log("APP SDK END SUCCESSFUL CATCH TIME and REJECTED >>>>>>>>>>", c);
          });
      } catch (error) {
        let b = Date.now();

        let c = b - a;
        console.log("APP SDK END ERROR CATCH TIME and REJECTED>>>>>>>>>>", c);
        console.log(" error happend for pipedrive sms init ", error);
      }
      resolve();
    });
  },
  pricingLink: function (appProperties) {
    return new Promise((resolve, reject) => {
      const { hash, osyncId, domain } = appProperties;
      var leftServiceId = urlParams.get("leftServiceId");
      var leftServiceName = urlParams.get("service");
      var allUrlParams = getAllUrlParams();
      var redirectSendSMSFromModule = domain + "/persons/list";
      console.log("allUrlParams>>>>", allUrlParams);
      var hrefUrl = `/pricing?productId=102&groupName=OMNI_CHANNEL&domain=${domain}&hash=${hash}&leftServiceId=${leftServiceId}&osyncId=${osyncId}&service=${leftServiceName}&urlParams=${allUrlParams}&serviceOrigin=${redirectSendSMSFromModule}`;

      console.log("hrefUrl >>>>>>>>>>>>>>>>>>>.", hrefUrl);
      resolve(hrefUrl);
    });
  },
  getWebhookContent: function (fetchData, fetchWebhookNotifications, appProperties) {
    return new Promise((resolve, reject) => {
      let integId = appProperties?.randomIntegId;
      fetchData("omessage/" + integId + "/webhookNotifications", "GET", null, appProperties).then((response) => {
        let webhookNotification = JSON.parse(response);
        console.log(webhookNotification, "useworkflow response");
        resolve(webhookNotification);
      });
    });
  },
  getUserData: function () {
    var companyId = urlParams.get("companyId");
    var emailId = urlParams.get("email");
    var userId = urlParams.get("userId");
    var domain = urlParams.get("domain");
    var companyOrgId = companyId;
    return new Promise((resolve, reject) => {
      var userData = {
        companyId: companyId,
        userId: userId,
        companyOrgId: companyOrgId,
        domain: domain,
        emailId: emailId,
      };
      resolve(userData);
    });
  },
  getDefaultModuleForAGivenModule: function (moduleName) {
    return new Promise((resolve, reject) => {
      if (moduleName === "deal") {
        resolve(["person"]);
      } else {
        resolve(moduleName);
      }
    });
  },
  savePhone: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getSavedNumbers: function () {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  getAssociatedObjectId: function (thisObj) {
    return new Promise((resolve, reject) => {
      console.log(urlParams.get("selectedIds"), "associated id");
      resolve(urlParams.get("selectedIds"));
    });
  },
  getAssociatedObjectType: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource"));
    });
  },
  getAssociatedObjectTypeInPlural: function (thisObj) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("resource") + "s");
    });
  },
  getAssociatedIntegId: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("integId"));
    });
  },
  getAssociatedHash: function (thisObj, rightServiceName) {
    return new Promise((resolve, reject) => {
      resolve(urlParams.get("hash"));
    });
  },

  fetchContact: function (fetchData) {
    return new Promise((resolve, reject) => {
      let phoneNumber = urlParams.get("phone")?.trim();
      console.log("dinku phoneNumber >>>>>>>>>>>>>>>>>>", phoneNumber);

      resolve(phoneNumber);
    });
  },
  getSubscriptionId: function (fetchSubscriptionData, appProperties) {
    return new Promise((resolve, reject) => {
      fetchSubscriptionData(appProperties).then((response) => {
        resolve(response);
      });
    });
  },
  doAfterSuccessfulEnable: function (dataObj) {
    return new Promise((resolve, reject) => {
      resolve();
    });
  },
  associatedModules: function () {
    return new Promise((resolve) => {
      resolve({
        person: ["person", "organization"],
        deal: ["deal", "person", "organization"],
        lead: ["lead", "person", "organization"],
        organization: ["organization"],
      });
    });
  },
  fetchDefaultFilterOptions: function (moduleName) {
    return new Promise((resolve, reject) => {
      let options = [
        {
          label: `All ${moduleName}`,
          value: "-1",
          filterId: "-1",
          filterName: `All ${moduleName}`,
          systemDefined: false,
        },
      ];
      resolve(options);
    });
  },
  getUrlParamsForRedirection: function (isNewWindow, appProperties) {
    const rightServiceId = appProperties.rightServiceId ?? urlParams.get("rightServiceId");
    const token = urlParams.get("token");
    return new Promise((resolve, reject) => {
      var redirectUrl = `leftServiceId=${appProperties.leftServiceId}&service=pdOmni&rightServiceId=${rightServiceId}&domain=org${appProperties.companyId}&userId=${appProperties.userId}&companyId=${appProperties.companyId}`;
      if (token) {
        redirectUrl += `&token=${token}`;
      }
      resolve(redirectUrl);
    });
  },
  fetchContactsCount: function (fetchFilterInfoByFilterId, appProperties, integId, module) {
    return new Promise((resolve, reject) => {
      var selectedIds = urlParams.get("selectedIds");
      var excludedIds = urlParams.get("excludedIds");
      this.getFilterObjectForShrinkView().then((filterObject) => {
        let filterId = filterObject?.filterId;
        let filterType = filterObject?.filterType;
        let filter = filterObject?.filter;

        if (!filterId && (selectedIds || excludedIds)) {
          filterId = "-1";
        }
        console.log("filterId fetchContactsCount >>>>>>>>>>>>>>>>", filterId);
        console.log(" log >>> filter >>>> ", filter);

        if (filterId) {
          fetchFilterInfoByFilterId(
            appProperties,
            integId,
            module,
            filterId,
            selectedIds,
            excludedIds,
            appProperties?.leftServiceId,
            filterType,
            filter
          ).then(function (response) {
            if (response?.count) {
              resolve(response?.count);
            }
          });
        }
      });
      // }
    });
  },

  getFilterObjectForShrinkView: function () {
    return new Promise((resolve, reject) => {
      var filterId = "";
      var filterType = 0;
      let filterFromParams = urlParams.get("filter");
      try {
        const filterJson = JSON.parse(filterFromParams);
        if (filterJson) {
          var filterObjectKey = Object.keys(this.filterTypeMap).find((filterTypeObjectKey) => filterJson[filterTypeObjectKey]);
          if (filterObjectKey !== "everyone") {
            filterType = this.filterTypeMap[filterObjectKey];
            filterId = filterJson[filterObjectKey];
          } else {
            filterId = "-1";
          }
        }
      } catch (error) {
        console.log(error);
      }
      resolve({
        filterId: filterId,
        filterType: filterType,
        filter: filterFromParams,
      });
    });
  },
  getApplicationToken: function (userId) {
    var token = urlParams.get("token");
    return new Promise((resolve, reject) => {
      resolve(token);
    });
  },
};
