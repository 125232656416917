import { Checkbox as AntDCheckbox } from "antd";

function Checkbox({ item, ...restProps }) {
  return (
    <AntDCheckbox {...restProps} value={item.value} className={`round-checkbox checkbox-checked-bg-violet ${restProps?.customClasses ?? ""}`}>
      {item?.label}
    </AntDCheckbox>
  );
}

export default Checkbox;
