import { Flex } from "antd";
import React from "react";
import Button from "./Button";

const ButtonGroup = ({
  buttons = [],
  size = "md",
  className = "",
  type = "primary",
  direction = "horizontal",
  gap = "small",
  align = "center",
  justify = "center",
}) => {
  const renderButtons = () => {
    return buttons.map((buttonProps, index) => {
      const { children } = buttonProps;
      return (
        <Button {...buttonProps} key={index} size={size} type={buttonProps?.type || type}>
          {children}
        </Button>
      );
    });
  };
  let alignMethod = "";
  if (direction === "vertical") alignMethod = "wrap";

  return (
    <Flex gap={gap} justify={justify} align={align} wrap={alignMethod} className={`w-100 ${className}`}>
      {renderButtons()}
    </Flex>
  );
};

export default ButtonGroup;
