import { Typography } from "antd";
import { useEffect, useState } from "react";

export default function Link({ baseUrl, children, appProperties }) {
  const { Link } = Typography;
  const [urlParams, setUrlParams] = useState("");
  useEffect(() => {
    appProperties?.controller?.getUrlParamsForRedirection(true, appProperties).then((urlParamsFromController) => {
      setUrlParams(urlParamsFromController);
    });
  }, [appProperties]);

  return (
    <span className="mx-1 hs-d-if">
      <Link className="hs-link d-flex align-items-center gap-1" href={baseUrl + urlParams} target="_blank">
        <Text>{children}</Text>
      </Link>
    </span>
  );
}

Link.Icon = Icon;

function Text({ children }) {
  return <>{children}</>;
}

function Icon({ icon }) {
  return <>{icon}</>;
}
