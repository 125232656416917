import { Button } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useCampaignApi from "../../../../hooks/useCampaign";
import { failureNotification } from "../../../utils/CommonNotifications";
import { ConfirmAlertModal } from "../../../utils/CommonVessels";
import { getCampaignStatusFromStatusCode, getClassNameForCampaignStatus, getClassNameForCampaignType } from "../../utils/campaignUtils";
import { capitalizeFirstLetter, getDefaultUnknownErrorMessageContent } from "./../../../utils/commonUtils";

const currentActionObject = {
  edit: {
    modalContent: "Editing will pause the campaign. Finish and relaunch to resume.",
    buttonConfirmText: "Edit",
    title: "Are you sure you want to pause?",
    icon: <div style={{ cursor: "default" }} className="actionIconsSprite campaignEdit" />,
    nextAction: "resume",
    useConfirmAlert: true,
    actionKey: "pause",
  },
  stop: {
    modalContent: "This action will permanently stop the running campaign.",
    buttonConfirmText: "Stop",
    title: "Are you sure you want to stop?",
    icon: <div style={{ cursor: "default" }} className="staticIconsSprite campaign-stop-icon" />,
    buttonClassName: "hs-bg-red",
    className: "hs-bg-red",
    nextAction: "none",
    useConfirmAlert: true,
    actionKey: "stop",
  },
  resume: {
    nextAction: "edit",
    actionButtonName: "Save and Launch",
    actionKey: "resume",
  },
  getCurrentActionName: (campaignStatus) => {
    const campaignStatusName = getCampaignStatusFromStatusCode(campaignStatus);
    switch (campaignStatusName) {
      case "paused":
        return "resume";
      case "scheduled":
        return "edit";
      default:
        return "none";
    }
  },
};

const CampaignActionArea = React.memo((props) => {
  const { initialValues, setInitialValues, showNotVisibleFilterBanner, sendCampaign, appProperties, showOnlyStop } = props;
  const { updateCampaignStatus } = useCampaignApi(appProperties);
  const [confirmActionModalOpen, setConfirmActionModalOpen] = useState(false);
  const currentActionStatus = showOnlyStop ? "stop" : currentActionObject.getCurrentActionName(initialValues?.campaignStatus);

  const [currentAction, setCurrentAction] = useState(currentActionStatus);
  const [resumeLoading, setResumeLoading] = useState(false);
  const disableForm = useCallback(
    (disable) => {
      setInitialValues((prev) => {
        const initValues = { ...prev, disabled: disable };
        if (disable && showOnlyStop) {
          initValues.filter = null;
        }
        return initValues;
      });
    },
    [setInitialValues]
  );

  useEffect(() => {
    if (currentAction === "resume") {
      disableForm(false);
    }
  }, [currentAction, disableForm]);

  if (currentAction === "none") return <></>;

  const processAction = async () => {
    try {
      const updateCampaignStatusResponse = await updateCampaignStatus(initialValues?.campaignId, currentActionObject[currentAction]?.actionKey);
      if (updateCampaignStatusResponse?.status === 200 && updateCampaignStatusResponse?.data) {
        setConfirmActionModalOpen(false);
        setCurrentAction((prev) => {
          return currentActionObject[prev]?.nextAction ?? "none";
        });
        setInitialValues((prev) => ({ ...prev, campaignStatus: updateCampaignStatusResponse?.data?.campaignStatus }));
      } else {
        failureNotification(getDefaultUnknownErrorMessageContent("Error updating Campaign status."));
      }
    } catch (e) {
      failureNotification(getDefaultUnknownErrorMessageContent(`Error updating Campaign status.`));
    }
  };

  const doResumeAction = async () => {
    const sendCampaignResponse = await sendCampaign(true);
    if (sendCampaignResponse) {
      if (sendCampaignResponse?.status) {
        setCurrentAction((prev) => currentActionObject[prev]?.nextAction);
      } else {
        failureNotification(sendCampaignResponse?.error);
      }
    }
  };

  const doAction = async () => {
    if (currentActionObject[currentAction]?.useConfirmAlert) {
      setConfirmActionModalOpen(true);
    } else {
      setResumeLoading(true);
      await doResumeAction();
      setResumeLoading(false);
    }
  };

  const doOnCancel = () => {
    setConfirmActionModalOpen(false);
  };

  const isStatusPaused = (status) => {
    if (status?.toLowerCase() === "resume") {
      return true;
    }
    return false;
  };

  const getActionHeaderName = (action, type) => {
    if (isStatusPaused(action)) {
      return "Paused";
    } else {
      return capitalizeFirstLetter(initialValues?.type);
    }
  };

  const getActionHeaderClassName = (action) => {
    if (isStatusPaused(action)) {
      return getClassNameForCampaignStatus("paused");
    } else {
      return getClassNameForCampaignType(initialValues?.type);
    }
  };

  const actionHeaderContent = getActionHeaderName(currentAction);

  const actionHeaderClass = getActionHeaderClassName(currentAction);

  return (
    <div className="row w-100 d-flex align-items-center justify-content-between campaignActionContainer mb-3">
      <ConfirmAlertModal
        onCancel={doOnCancel}
        open={confirmActionModalOpen}
        onConfirm={processAction}
        centered={true}
        alertIcon={currentActionObject[currentAction]?.icon}
        titleContent={currentActionObject[currentAction]?.title}
        modalContent={currentActionObject[currentAction]?.modalContent}
        buttonConfirm={currentActionObject[currentAction]?.buttonConfirmText}
        buttonClassName={currentActionObject[currentAction]?.buttonClassName}
      />
      <div className="col-md-5 text-center d-flex justify-content-center">
        <div className={`w-75 campaignActionTitleContainer hs-border-30 py-3 ${actionHeaderClass}`}>
          <div className="hs-fw-600 ">{actionHeaderContent}</div>
        </div>
      </div>
      <div className="col-md-5 campaignActionButtonsContainer d-flex gap-3 justify-content-end me-4">
        <Button
          className={`${currentActionObject[currentAction]?.className ?? "hs-bg-violet"} hs-border-10 lg-button hs-fs-16 px-3`}
          type="primary"
          name="actionButton"
          disabled={false || (!initialValues?.disabled && showNotVisibleFilterBanner)}
          loading={resumeLoading}
          onClick={doAction}
        >
          <span className="px-4">{getActionAreaName(currentAction)}</span>
        </Button>
      </div>
    </div>
  );
});

const getActionAreaName = (currentAction) => {
  return currentActionObject[currentAction]?.actionButtonName ?? capitalizeFirstLetter(currentAction);
};

export default CampaignActionArea;
