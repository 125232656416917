import Modal from "../Modal";
import { config } from "../../config";

export default function InfoModal({ open, handleCancel, handleOk, title, content, type, okButtonText, cancelButtonText }) {
  return (
    <Modal
      open={open}
      contentSpaceSize={"small"}
      rootClassName="hs-w-25-rem"
      okButtonProps={{
        buttonText: okButtonText,
        onClick: handleOk,
      }}
      cancelButtonProps={{
        buttonText: cancelButtonText,
        onClick: handleCancel,
      }}
      modalContentClassName="d-grid gap-5"
      alignButton="center"
    >
      <div className="d-grid gap-4">
        <div className={`mx-auto actionIconsSprite infoModalIcon ${config.modal[type]?.iconClassName ?? ""}`} />
        <div>
          <div className="text-center hs-fw-550 hs-fs-15 mb-2">{title}</div>
          <div className="text-center">{content}</div>
        </div>
      </div>
    </Modal>
  );
}
