import { Input as AntdInput, Form } from "antd";
import React, { useState } from "react";
import { config } from "../../config";
import LabelAndValue from "../../form/LabelAndValue";
import useInstantFocus from "../hooks/useInstantFocus";

function Input(props) {
  const [hasError, setHasError] = useState(false);
  const {
    autoFocus,
    formItemName,
    formItemLabel,
    required,
    errorMessage,
    validator,
    size = "m",
    formItemRules = required
      ? [
          {
            required: true,
            validator: validator
              ? validator
              : (_, value) => {
                  if (!value) {
                    setHasError(true);
                    return Promise.reject(new Error(errorMessage ?? "Required"));
                  } else {
                    setHasError(false);
                    return Promise.resolve();
                  }
                },
          },
        ]
      : [],
    formClassName = "",
    customClasses = "",
    value,
    name = "",
    prefix = null,
  } = props;

  const inputConfiguration = config.input ?? {};
  const inputConfigurationBySize = config.input.size[size] ?? {};

  const inputRef = useInstantFocus(autoFocus);

  return (
    <LabelAndValue label={formItemLabel} hasError={hasError}>
      <Form.Item rules={formItemRules} name={formItemName} className={`mb-0 ${formClassName}`}>
        <AntdInput
          {...props}
          name={name}
          value={value}
          ref={inputRef}
          autoComplete="off"
          tabIndex={-1}
          className={`${inputConfigurationBySize?.customClasses ?? ""} ${inputConfiguration?.customClasses ?? ""} ${customClasses} ${
            hasError ? "hs-input-has-error" : ""
          }`}
          prefix={prefix}
        />
      </Form.Item>
    </LabelAndValue>
  );
}

export default React.memo(Input);
