import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { failureNotification, successNotification, successNotificationViewReport } from "../pages/utils/CommonNotifications";
import { getDefaultUnknownErrorMessageContent } from "../pages/utils/commonUtils";
import useHttp from "./useHttp";
import useMessage from "./useMessage";
import { Button, notification } from "antd";

function useCampaignApi(appProperties) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchData, fetchDataByPagination } = useHttp();
  const { sendMessage, loading: testLoading } = useMessage(appProperties);

  const sendCampaignMessage = async (payload, integId, isUpdate, shrinkView) => {
    setLoading(true);
    let paramsToAppend = location.search;
    if (shrinkView) {
      paramsToAppend = await appProperties?.controller?.getUrlParamsForRedirection(true, appProperties);
    }
    const campaignUrl = isUpdate ? "campaign/update" : `${integId}/campaign/send`;
    const method = "POST";
    try {
      const response = await fetchData(campaignUrl, method, payload, appProperties, { "Content-Type": "multipart/form-data" });
      if (response) {
        const responseJson = JSON.parse(response);
        if (responseJson?.data?.status) {
          if (shrinkView) {
            successNotificationViewReport(
              <>
                {" "}
                <div className="ms-2">
                  Message campaign started successfully! Monitor analytics
                  <span> for insights.</span>
                </div>
                <div className="mt-2 justify-content-end d-flex">
                  <Button
                    style={{
                      color: "white",
                      background: "#605BFF",
                      fontSize: 13,
                    }}
                    type="text"
                    target="_blank"
                    href={"/campaigns/list?" + paramsToAppend}
                  >
                    View Report
                  </Button>
                </div>
              </>
            );
          } else {
            navigate("/campaigns/list" + location.search);
            successNotification("SMS campaign started successfully! Monitor analytics for insights.");
          }
          return responseJson?.data;
          // return responseJson.data;
        } else {
          const errorMessage = responseJson?.data?.error;
          if (errorMessage) {
            failureNotification("Campaign failed", errorMessage);
          } else {
            failureNotification("Campaign failed", getDefaultUnknownErrorMessageContent());
          }
          return responseJson?.data;
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const sendTestMessage = async (payload, integId) => {
    try {
      const response = await sendMessage(payload, integId);
      return response;
    } catch (error) {}
  };

  const getCampaignOverview = useCallback(
    async (campaignId, paginationRef, limit) => {
      setLoading(true);
      let fetchLimit = limit ?? 500;
      let targetUrl = `campaign/${campaignId}/overview?limit=${fetchLimit}`;
      if (paginationRef.current?.lastKey) {
        targetUrl += `&lastKey=${encodeURIComponent(paginationRef.current?.lastKey)}`;
      }
      let response = await fetchData(targetUrl, "GET", null, appProperties);
      try {
        response = JSON.parse(response);
        return response;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [fetchData]
  );

  const getAllCampaigns = useCallback(
    async (limit, paginationParams) => {
      try {
        setLoading(true);
        const leftServiceId = appProperties?.leftServiceId;
        let targetUrl = `campaigns/list?leftServiceId=${leftServiceId}`;
        return await fetchDataByPagination(targetUrl, "GET", null, appProperties, null, limit, paginationParams);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [appProperties, fetchDataByPagination]
  );

  const getCampaignByCampaignId = async (campaignId) => {
    setLoading(true);
    try {
      let response = await fetchData(`campaign/${campaignId}`, "GET", null, appProperties);
      if (response) {
        response = JSON.parse(response);
        return response;
      }
    } catch (e) {
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const updateCampaignStatus = useCallback(
    async (campaignId, status) => {
      try {
        let targetUrl = `campaign/${campaignId}/${status}`;
        const response = await fetchData(targetUrl, "POST", null, appProperties);
        if (response) {
          return JSON.parse(response);
        } else {
          throw new Error();
        }
      } catch (err) {
        failureNotification("Campaign status update failed!");
      } finally {
      }
    },
    [appProperties, fetchData]
  );

  return {
    sendCampaignMessage,
    sendTestMessage,
    getAllCampaigns,
    loading,
    setLoading,
    testLoading,
    updateCampaignStatus,
    getCampaignOverview,
    getCampaignByCampaignId,
  };
}
export default useCampaignApi;
