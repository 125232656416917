import Link from "../components/custom/info/Link.jsx";
import AccountPhone from "../pages/OnboardingComponents/AccountPhone.jsx";
import AddPhone from "../pages/OnboardingComponents/AddPhone.jsx";
import AuthorizePage from "../pages/OnboardingComponents/AuthorizePage";
import GuidetoSendSms from "../pages/OnboardingComponents/GuideToSendSms.jsx";
import OnboardingFinishModalContent from "../pages/OnboardingComponents/OnboardingFinishModalContent.jsx";
import { WHATSAPP_DISPLAY_NAME } from "./AppConstants.js";

export const ProviderCommonObj = {
  isMMSSupported: false,
  isAlphaSenderSupported: false,
  category: "SMS",
  isMessagingServiceSenderSupported: false,
  isEnableInboundSms: false,
  app: "",
  title: "",
  AuthorizationType: "",
  loginContent: [],
  steps: [
    {
      title: "account",
      description: "Account",
      content: <AccountPhone />,
    },
    {
      title: "installation",
      description: "Installation",
      content: <AuthorizePage />,
    },
    {
      title: "addPhone",
      description: "Add Phone",
      content: <AddPhone />,
    },
    {
      title: "guide",
      description: "Guide to send SMS",
      content: <GuidetoSendSms />,
    },
  ],
  menu: [],
  supportedAttachmentTypes: {
    image: "image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/tiff, text/vcard, video/mp4, video/mpeg, audio/mpeg",
  },
  maximumAttachmentSizeInBytes: 5242880,
  maximumAttachmentLimit: 5,
};

export const ProviderExtendedCommonObj = {
  hello_send: {
    howToAuthLink: null,
    app: "hello_send",
    isEnableInboundSms: false,
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isMessagingServiceSenderSupported: false,
    onBoardingConnected: true,
  },
  twilio: {
    AuthorizationType: "auth",
    AuthorizeReference: [
      <>
        Go to{" "}
        <a href="https://www.twilio.com/" target="_blank" rel="noopener noreferrer">
          https://www.twilio.com/
        </a>{" "}
        and click on the 'Sign up' button to create a new account. If you already have an account, log in to your account.
      </>,
      "On the dashboard, you will see your Account SID and Auth token.",
      "Copy your Account SID and Auth token and use them to authenticate Twilio service.",
    ],
    AuthorizeReferenceText: "That's it! You now have your Twilio Account SID and Auth token.",
    AuthorizeReferenceURL: "https://www.youtube.com/embed/hv-P_qDN2-Y",
    app: "twilio",
    category: "sms",
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Twilio_",
    title: "Authorize Twilio to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
      file: "application/pdf, application/vcard, application/vnd.apple.pkpass, text/vcard, text/x-vcard, text/csv, text/rtf, text/richtext, text/calendar, text/directory",
    },
    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
      {
        label: "Messaging Service",
        key: "MessagingService",
      },
    ],
  },
  twilio_whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/whatsapp-for-pipedrive",
    AuthorizationType: "auth",
    app: "twilio_whatsapp",
    category: "sms",
    title: "Authorize Whatsapp-Twilio and configure all your SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png,image/jpg,image/gif,image/jpeg,image/bmp,image/tiff",
      file: "application/pdf,application/vcard,application/vnd.apple.pkpass,text/vcard,text/x-vcard,text/csv,text/rtf,text/richtext,text/calendar,text/directory",
      audio:
        "audio/basic,audio/L24,audio/mp4,audio/mpeg,audio/ogg,audio/vnd.rn-realaudio,audio/vnd.wave,audio/3gpp,audio/3gpp2,audio/ac3,audio/webm,audio/amr-nb,audio/amr",
      video:
        "video/mpeg,video/mp4,video/quicktime,video/webm,video/3gpp,video/3gpp2,video/3gpp-tt,video/H261,video/H263,video/H263-1998,video/H263-2000,video/H264",
    },

    maximumAttachmentSizeInBytes: 5242880,
    maximumAttachmentLimit: 5,
    isAlphaSenderSupported: true,
    isEnableInboundSms: false,
    isMessagingServiceSenderSupported: true,
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  clicksend: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_ClickSend",
    AuthorizationType: "auth",
    app: "clicksend",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Clicksend and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  plivo: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Plivo",
    AuthorizationType: "auth",
    app: "plivo",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Plivo and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  vonage: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Vonage",
    AuthorizationType: "auth",
    app: "vonage",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Vonage and configure all your SMS numbers.",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  burstsms: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_BurstSMS",
    AuthorizationType: "auth",
    isEnableInboundSms: false,
    category: "sms",
    title: "Authorize Burstsms and configure all your SMS numbers.",
    app: "burstsms",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  telnyx: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Telnyx",
    category: "sms",
    app: "telnyx",
    title: "Authorize Telnyx and configure all your SMS numbers.",
    AuthorizationType: "auth",
    menu: [
      {
        label: "Add Phone",
        key: "Phone number",
      },
    ],
  },
  facebook: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "messenger",
    AuthorizationType: "outh",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    isEnableInboundSms: false,
    app: "facebook",
    title: "Connect facebook and configure all pages",
    menu: [
      {
        label: "Select Pages",
        key: "Select Pages",
      },
    ],
  },
  ringcentral: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_RingCentral",
    AuthorizationType: "oauth",
    AuthorizeReference: [
      "Begin by clicking on the 'Authorize' button.",
      "Enter your RingCentral username and password.",
      "Click on the 'Authorize' button to grant permission.",
      "Once completed, your RingCentral account is connected.",
      "In the right panel, you'll find all SMS-enabled numbers available for configurations.",
    ],
    AuthorizeReferenceURL: "https://www.youtube.com/embed/U_HI1fQe8MM?si=oGTp9XcgM-lCXQY0",
    category: "sms",
    isEnableInboundSms: true,
    app: "ringcentral",
    title: "Authorize Ringcentral to connect SMS numbers.",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
  },
  whatsapp: {
    howToAuthLink: "https://help.oapps.xyz/portal/en/kb/articles/how-to-authorise#How_to_Authorise_Facebook",
    category: "whatsapp",
    AuthorizationType: "oauth",
    supportedAttachmentTypes: {
      image: "image/png, image/jpeg, image/jpg, image/gif",
    },
    maximumAttachmentLimit: 5,
    maximumAttachmentSizeInBytes: 5242880,
    onAuthorizeOpenSuccessModal: true,
    isMMSSupported: true,
    isEnableInboundSms: false,
    app: "whatsapp",
    title: `Connect ${WHATSAPP_DISPLAY_NAME} and configure all numbers`,
    description: (
      <>
        Connect {WHATSAPP_DISPLAY_NAME} business numbers and bring all your conversations to one place. To know more
        <Link href="https://help.oapps.xyz/portal/en/kb/articles/hellosend-whatsapp-integration">Click here</Link>
      </>
    ),
    note: (
      <>
        Enjoy your first 1,000 conversations free! After that, {WHATSAPP_DISPLAY_NAME} charges per conversation, covering all messages exchanged
        within a <span className="hs-word-break-keep-all">24-hour</span> session.
      </>
    ),
    helper: [
      <>
        Click <b>'Connect'</b> to log into your Facebook Business account.
      </>,
      <>Follow the steps to link or register your {WHATSAPP_DISPLAY_NAME} number and back up your data if using a personal number.</>,
    ],
    successAuthModal: {
      title: "Congratulations !",
      content: `Your account has been successfully connected your ${WHATSAPP_DISPLAY_NAME} account. Please add Templates to continue.`,
      button: {
        ok: {
          text: "Add Templates",
        },
        cancel: {
          text: "Cancel",
        },
      },
    },
    onBoardingFlow: {
      getFinishModalComponent: (appProperties) => {
        return {
          render: <OnboardingFinishModalContent serviceName="whatsapp" />,
          steps: [
            {
              title: (
                <>
                  Authorization of {WHATSAPP_DISPLAY_NAME} Service -
                  <Link baseUrl={"https://ant.design/components/typography"}>How to Authorize</Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Configuration of {WHATSAPP_DISPLAY_NAME}-Enabled Number -
                  <Link baseUrl={"https://ant.design/components/typography"}>Setup Guide</Link>
                </>
              ),
            },
            {
              title: (
                <>
                  Importing of {WHATSAPP_DISPLAY_NAME} Templates -
                  <Link appProperties={appProperties} baseUrl={"/settings/templates?openTemplate=true&ttype=wp&"}>
                    Add Templates
                  </Link>
                </>
              ),
            },
          ],
          calculateStep: async (fetchSavedTemplates) => {
            return new Promise((resolve, reject) => {
              var step = 0;
              const whatsappProp = appProperties.installedApps.find((installedApp) => installedApp?.right?.service_name === "whatsapp");
              if (whatsappProp?.right?.auth?.authorized) {
                step = step + 2;
              }

              fetchSavedTemplates?.(appProperties)
                .then((res) => {
                  if (res?.success) {
                    const hasWhatsappTemplate = res.data?.some((template) => template.integId === whatsappProp?.integProps?.integId);
                    if (hasWhatsappTemplate) {
                      step = step + 1;
                    }
                  }
                })
                .finally(() => {
                  resolve(step);
                });
            });
          },
          getGallery: (appName) => [
            {
              headline: "Configuring Automated SMS in 2 Simple Steps:",
              sourceUrl: "https://www.youtube.com/embed/iv2i324FmRc?rel=0",
              steps: ["Create Webhook URL", `Set Up an Automation in ${appName}`],
            },
            {
              headline: `Automate Text Messages When Deal Stages Change in ${appName}`,
              sourceUrl: "https://www.youtube.com/embed/nxXgaxZPXU4?rel=0",
              steps: ["Customize SMS Templates for Every Pipeline Stage", `Efficiently Map and Automate with Webhooks`],
            },
            {
              headline: `Automate SMS in ${appName}`,
              sourceUrl: "https://www.youtube.com/embed/iUayvyukksM?rel=0",
              steps: ["Create templates and Automated webhooks.", `Create workflow rules for business requirements and automate SMS`],
            },
          ],
        };
      },
    },
  },
};
