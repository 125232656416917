import { Form } from "antd";
import Select from "../input/select/Select";
import Input from "../input/input/Input";
import Button from "../input/button/Button";
import ButtonGroup from "../input/button/ButtonGroup";

export default function GetVariableValues({
  handleOnSelect = () => {},
  fields,
  handleOnChange = () => {},
  getVariableValuesForm,
  okButtonProps,
  cancelButtonProps,
  selectSource,
}) {
  return (
    <div className="hs-w-500-px p-2">
      <Form form={getVariableValuesForm} className="d-grid gap-3">
        <Select
          label={"Select Fields"}
          placeHolder={"Select"}
          customProps={{
            placeholder: "Select Template",
            bordered: null,
            tabIndex: 3,
            onSelect: handleOnSelect,
          }}
          options={fields}
          name="selectedField"
          required={true}
          selectSource={selectSource}
        />
        <Input formItemLabel={"Enter default value"} formItemName={"defaultValue"} onChange={handleOnChange} required={true} />
        <div className="d-flex justify-content-center">
          <ButtonGroup
            className="hs-w-90"
            align="center"
            buttons={[
              {
                variant: "cancel",
                type: "secondary",
                children: cancelButtonProps?.buttonText,
                ...cancelButtonProps,
              },
              {
                children: okButtonProps?.buttonText,
                ...okButtonProps,
              },
            ]}
            size="l"
          />
        </div>
      </Form>
    </div>
  );
}
